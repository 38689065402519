<template>
    <div class="gray-bg py-5">
        <div class="container">
            <h1 class="heading-l fw-bold">BCF Submission</h1>

            <div class="row">
                <div class="col-md-10 mb-3 mb-md-0">
                    <div class="row gy-3 gx-3">
                        <div class="col-md-6">
                            <input
                                id="txt-searchTerms"
                                name="searchTerms"
                                placeholder="Search through BCF listings..."
                                v-model.lazy="filters.keywords"
                                @change="onSearchChange"
                                type="text">
                        </div>
                        <!-- <div class="col-md-2">
                            <SelectControl
                                name="submissionYear"
                                class="select-reset mb-0"
                                :items="submissionYearCategories"
                                v-model="filters.submissionYear"
                                item-text="name"
                                item-value="key"
                            ></SelectControl>
                        </div> -->
                        <div class="col-md-3">
                            <SelectControl
                                name="category"
                                class="select-reset mb-0"
                                :items="generalGenreCategories"
                                v-model="filters.categoryType"
                                @change="onChangeCategoryType"
                                item-text="name"
                                item-value="key"
                            ></SelectControl>
                        </div>
                        <div class="col-md-3">
                            <SelectControl
                                name="subCategory"
                                class="select-reset mb-0"
                                :items="selectedSubCategories"
                                v-model="filters.subCategory"
                                item-text="name"
                                item-value="key"
                            ></SelectControl>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <button
                        @click="onSearchChange"
                        class="btn btn-secondary btn-lg w-100"
                        type="button">
                        Search
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="bcf-listing container pt-5 py-4">
        <p v-if="paginatedData && paginatedData.count" class="subheading text-muted">
            Showing
            <div v-if="isBusy" class="placeholder bg-primary w-25 subheading"></div>
            <span v-else>{{ paginationStatusLabel }}</span>
        </p>
        <FadeInOut mode="out-in" :duration="300">
            <GridPlaceholder
                v-if="isBusy"
                grid-config="1x3"
                grid-config-lg="3x2"
                grid-item-ratio="4x3"
            ></GridPlaceholder>
            <div v-else-if="paginatedData && paginatedData.count > 0">
                <div class="row gy-3">
                    <div v-if="paginatedData && paginatedData.items" v-for="(item, index) in paginatedData?.items" class="col-md-6 col-lg-4">
                        <div class="card rounded-3 border border-1 h-100 bg-transparent">
                            <div class="position-relative">
                                <div class="ratio ratio-16x9">
                                    <img :src="item.thumbnail" alt="test">
                                </div>
                                <div class="position-absolute top-0 start-0 p-3">
                                    <div class="d-flex">
                                        <div class="badge px-2 rounded-pill text-capitalize text-white text-bg-primary"
                                            :class="{
                                                'text-bg-warning': item.submissionYear === 2023
                                            }">
                                            <span v-if="item.submissionYear === 2023">2023</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-3 vstack bg-white">
                                <div class="bg-white vstack h-100 justify-content-between">
                                    <BcfSubmissionDetailModal :bcf-listing-detail="item"></BcfSubmissionDetailModal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="display-l text-muted">Sorry, there are no BCF Submissions matching your criteria...</p>
            </div>
        </FadeInOut>
        <div class="d-flex pt-4">
            <Pagination
                v-if="paginatedData?.currentPageNumber"
                :page-number="paginatedData?.currentPageNumber"
                :total-pages="paginatedData?.totalPages"
                :pages-per-view="paginatedData.pageSize"
                @page-change="onPageChange"
            ></Pagination>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBroadcastPortalBcfListingStore } from '../../store';
import { computed, ref, onMounted } from 'vue';
import { GridPlaceholder, Pagination } from '@/modules/core/components';
import { BcfSubmissionDetailModal } from '.';
import { FadeInOut } from 'vue3-transitions';
import SelectControl from '@/modules/core/components/forms/select-control.vue';

const bcfListingStore = useBroadcastPortalBcfListingStore()
const { filters, isBusy, paginatedData } = storeToRefs(bcfListingStore)

// const categories = ref([
//     { name: 'All', key: '' },
//     { name: 'General', key: 'generalCategory' },
//     { name: 'Institute of Higher Learning', key: 'ihlCategory' },
// ]);

const yearFromUrl = window.location.href.split('/').reverse()[1]

const generalGenreCategories = ref([
    { name: 'All', key: ''},
    { name: 'Drama', key: 'drama' },
    { name: 'Comedy', key: 'comedy' },
    { name: 'Horror', key: 'horror' },
    { name: 'Advertisement', key: 'advertisement' },
    { name: 'Documentary', key: 'documentary' },
    { name: 'Public Service Announcement', key: 'psa' },
    { name: 'Music Video', key: 'musicvideo' },
    { name: 'Featured Film', key: 'featured' },
    { name: 'Short Film', key: 'shortfilm' },
    { name: 'Short Film under Institute of Higher Learning (IHL)', key: 'shortfilmihl' },
])

const ihlGenreCategories = ref([
    { name: 'All', key: ''},
    { name: 'Fiction', key: 'Fiction' },
    { name: 'Non-Fiction', key: 'Non-Fiction' },
    { name: 'Documentary (IHL)', key: 'IHL-Documentary' },
    { name: 'Animation', key: 'Animation' },
])

// const submissionYearCategories = ref([
//     { name: 'All', key: '', isNull: true},
//     { name: '2023', key: 2023},
//     { name: '2024', key: 2024}
// ])

const selectedSubCategories = computed(() => {
    if (filters.value.categoryType === 'shortfilmihl') {
        return ihlGenreCategories.value;
    }

    return [{ name: 'Select Subcategory', key: '' }];
});

const onChangeCategoryType = () => {
    filters.value.subCategory = selectedSubCategories.value[0].key;
}

const paginationStatusLabel = computed(() => {

    const min = ((paginatedData.value!.currentPageNumber - 1) * paginatedData.value!.pageSize) + 1;
    const max = Math.min(paginatedData.value!.currentPageNumber * paginatedData.value!.pageSize, paginatedData.value!.count);

    return `${min}-${max} out of ${paginatedData.value!.count}`;
})

const onPageChange = (pageNumber: number) => {
    filters.value.pageNumber = pageNumber;
    bcfListingStore.loadBcfListing();
}

const onSearchChange = () => {
    bcfListingStore.filters.keywords = filters.value.keywords;
    bcfListingStore.filters.subCategory = filters.value.subCategory;
    bcfListingStore.filters.categoryType = filters.value.categoryType;
    bcfListingStore.filters.submissionYear = filters.value.submissionYear;
    bcfListingStore.filters.pageNumber = 1;
    bcfListingStore.loadBcfListing();
}

onMounted(() => {
    filters.value.submissionYear = parseInt(yearFromUrl);
    bcfListingStore.loadBcfListing()
})
</script>

<style>

.select-reset select {
    margin-bottom: 0;
}

</style>
